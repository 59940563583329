import { styled } from "linaria/react"
import React from "react"
import Layout from "../components/layout"
import Heading from "../components/Layout/heading"
import MaxWidth from "../components/Layout/maxWidth"
import SEO from "../components/seo"
import HR from "../components/Layout/hr"

export default function ContactUs() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Heading>
        <h1>Contact Us</h1>
      </Heading>
      <MaxWidth>
        <Flex>
          <div>
            <h3>Contact Details:</h3>
            <div>
              Dr. Laco Kacani
              <br />
              CEO
              <br />
              Tel. +43-699-19203322
              <br />
              laco.kacani@inprosperis.eu
              <br />
              www.inprosperis.com
              <br />
            </div>
          </div>
          <div>
            <h3>Registered Address:</h3>
            <div>
              <address>
                Inprosperis e.U.
                <br />
                Schneeburggasse 39/1
                <br />
                A-6020 Innsbruck
                <br />
                AUSTRIA
                <br />
              </address>
              <em>
                Commercial Register Court: Regional Court Innsbruck
                <br />
                Company registration number: FN 481727 y
              </em>
            </div>
          </div>
        </Flex>
        <HR m={"2em 0"} />

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.2803672775863!2d11.378662914933338!3d47.26977361917921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479d6bf3be565ddb%3A0x5ba7f8665696342a!2sSchneeburggasse+39%2C+6020+Innsbruck!5e0!3m2!1sen!2sat!4v1551800491634"
          width="100%"
          height="450"
          frameborder="0"
          allowfullscreen
        />
      </MaxWidth>
    </Layout>
  )
}

const Flex = styled.section`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  > div {
    flex: 0 0 calc(50% - 1em);
  }

  address {
    margin-bottom: 2em;
    text-transform: none;
    font-style: normal;
  }
`
